 <template>
  <div>
    <div class="major-layout">
      <div class="row">
        <div class="first-container col-md-8">
        <div class="container">

          <div class="content">
            <div class="left-content">
              <h3 class="current-have">Current avaiable points</h3>
              <p class="first-point">{{ (readyPoints).toFixed(2) || 0 }}<span class="text-point">points</span></p>
              <p class="">Accumulated points {{ (totalPoints).toFixed(2) }}</p>
              <p class="conversion-message">You've converted <span>{{ (totalPointsConverted) }} points</span> <button
                  @click="scrollToHistory('pointhistory')">(See history)</button></p>
              <button class="convert-button" data-toggle="modal" data-target="#pointsConvertion" :disabled="canConvert == 'NO'">Convert</button>
            </div>

            <div class="right-content">
              <div class="top-point-container">
                <p class="team-commission">Points From All Team Commissions</p>
                <p class="indirect-points">{{ (indirectPoints).toFixed(2) || 0 }}</p>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="left-content" v-if="nextRewardItem">
              <div class="reward-info">
                <p class="reward-info-text">Next Smart Reward</p>
                <p class="reward-info-gas">{{ nextRewardItem.name }}</p>
                <!-- Math.floor(this.totalPoints) -->
                <p class="points-to-go" v-if=" Math.floor(nextRewardItem.points_remaining) > 0">{{ Math.floor(nextRewardItem.points_remaining) || 0}} points to go</p>
                <p class="points-to-go" v-if=" Math.floor(nextRewardItem.points_remaining) <= 0"> 0 points to go</p>
                <!-- <p class="points-to-go" v-if=" Math.floor(nextRewardItem.points) - Math.floor(this.readyPoints) > 0">{{ Math.floor(nextRewardItem.points) - Math.floor(this.readyPoints) || 0}} points to go</p>
                <p class="points-to-go" v-if=" Math.floor(nextRewardItem.points) - Math.floor(this.readyPoints) <= 0"> 0 points to go</p> -->
              </div>
            </div>

            <div class="right-content" v-if="directPoints">
              <div class="second-commission">
                <p class="direct-commission">Points From All Direct Commissions</p>
                <p class="direct-points">{{ (directPoints).toFixed(2) || 0 }}</p>
              </div>
            </div>
          </div>

          <div>
            <span class="smart-rewards-container">
              <p class="smart-rewards">SMART Rewards</p>
              <p class="smart-points-text">Convert your earned SMART Points to
                awesome
                rewards brought to you by <a href="https://smart.myshelta.com" class="">smart.myshelta.com</a></p>
            </span>

            <div class="d-none">
              <div class="text-center" v-if="loading">
                <!-- Loader component -->
                <Loader />
              </div>
              <div v-else-if="smartItems.length === 0">
                <!-- No smart items available -->
                <div class="no-items-available">No smart items available</div>
              </div>
              <div v-else>
                <ul class="smart-list">
                  <li class="smart-item" v-for="(smartItem, index) in smartItems" :key="index"
                    @click="showSmartItemModal(smartItem)">
                    <div class="smart-item-container">
                      <div class="smart-item-image">
                        <img :src="smartItem.imageUrl" alt="" class="">
                      </div>
                      <p class="smart-item-name">{{ smartItem.name }}</p>
                    </div>
                    <!-- <hr class="horizontal-line"/> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content d-flex justify-content-center" style="flex-direction: column;">
            <div class="text-center" v-if="loading">
              <!-- Loader component -->
              <Loader />
            </div>
            <div v-else-if="smartItems.length == 0">
              <!-- No smart items available -->
              <div class="no-items-available">No smart items available</div>
            </div>
            <div v-else class="d-flex reward-item-holder">
              <div class="the-wrapper" v-for="(smartItem, index) in smartItems" :key="index">
                <div class="reward-item" @click="showSmartItemModal(smartItem)">
                  <div class="img-holder">
                    <img :src="smartItem.imageUrl" alt="coins">
                  </div>
                  <h6>{{smartItem.name}}</h6>
                </div>
              </div>
            </div>
          </div>

          <div id="smartItemModal" class="modal" style="overflow: scroll; height: 100%;" v-if="selectedSmartItem">
            <SmartItemModal :selectedSmartItem="selectedSmartItem" @close-modal="closeSmartItemModal" />
          </div>
        </div>

      </div>

      <div class="col-md-4">
        <!-- Points history -->
        <aside class="second-container text-center" ref="pointhistory">
          <!-- <h4>History</h4> -->
          <PointsLogHistory :userIDD="userIDD" />
        </aside>
        <!-- Points history -->
      </div>
      </div>

      <!-- Modal for points convertion to cash begins -->
      <div class="convert-ppint-modal">
        <div
          tabindex="-1"
          class="modal fade"
          id="pointsConvertion"
          role="dialog"
          aria-labelledby="pointsConvertion"
          aria-hidden="true"
        >
        <!-- modal-lg -->
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="margin-bottom: 30px; max-width: none;">
            <div class="modal-header">
              <h5 class="modal-title confirmation-header">
                Convert Points
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" style="font-weight: normal">X</span>
              </button>
            </div>
            <div class="modal-body points-body">
              <p class="total-points-text">Current avaiable points</p>
              <h5 class="total-points-number">{{ Math.floor(readyPoints) || 0}} Points</h5>

              <h6>How many points would you want to convert?</h6>

              <div>
                <div class="row">
                  <div class="points-btn1">
                    <button class="btn" @click="activeButtons(20)" :class="[selectedpoints === 20 ? 'activePointBtn' : '' ]">20 points</button>
                    <button class="btn" @click="activeButtons(50)" :class="[selectedpoints === 50 ? 'activePointBtn' : '' ]">50 points</button>
                    <button class="btn" @click="activeButtons(100)" :class="[selectedpoints === 100 ? 'activePointBtn' : '' ]">100 points</button>
                    <button class="btn" @click="activeButtons(200)" :class="[selectedpoints === 200 ? 'activePointBtn' : '' ]">200 points</button>
                  </div>
                  <div class="points-btn2">
                    <button class="btn" @click="activeButtons(500)" :class="[selectedpoints === 500 ? 'activePointBtn' : '' ]">500 points</button>
                    <button class="btn" @click="activeButtons(1000)" :class="[selectedpoints === 1000 ? 'activePointBtn' : '' ]">1,000 points</button>
                    <button class="btn" @click="activeButtons(5000)" :class="[selectedpoints === 5000 ? 'activePointBtn' : '' ]">5,000 points</button>
                    <button class="btn" @click="activeButtons(10000)" :class="[selectedpoints === 10000 ? 'activePointBtn' : '' ]">10,000 points</button>
                  </div>
                </div>
                <div v-if="selectedpoints != 0">
                  <!-- <input disabled :value="(selectedpoints * 1000).toLocaleString()" type="text" class="form-control text-input" id="convertAmount" placeholder="Points amounts"> -->
                  <hr>

                  <div class="amount-breakdown">
                    <div class="d-flex justify-content-between mb-2">
                      <h6>Total cash value</h6>
                      <h6>{{ (selectedpoints * 1000).toLocaleString() }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <h6>Conversion charge</h6>
                      <h6>5%</h6>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                      <h6>Amount Receivable</h6>
                      <h6>{{ (amountDeduction).toLocaleString() }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer modal-button orange-btn">
              <button type="button" class="btn cancel" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn continue"
                :disabled="selectedpoints == 0"
                data-dismiss="modal"
                aria-label="Close"
                data-toggle="modal"
                data-target="#confirmPointConv"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
      <!-- Modal for points convertion ends -->

      <!-- Point conversion confirmation modal starts -->
      <div class="convert-ppint-modal">
        <div
          tabindex="-1"
          class="modal fade"
          id="confirmPointConv"
          role="dialog"
          aria-labelledby="confirmPointConv"
          aria-hidden="true"
        >
        <!-- modal-lg -->
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="margin-bottom: 30px; max-width: none;">
            <div class="modal-header">
              <h5 class="modal-title confirmation-header">
                Confirm conversion
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" style="font-weight: normal">X</span>
              </button>
            </div>
            <div class="modal-body points-body">
              <img src="../assets/images/points-icon.svg" alt="points" class="d-flex m-auto pb-3">
              <h6 class="total-points-number mb-0">{{ (selectedpoints).toFixed(2) || 0}} Points will be decucted from your point</h6>
              <h6>Do you want to proceed?</h6>
              <div>
                
              </div>
            </div>
            <div class="modal-footer modal-button orange-btn">
              <button type="button" class="btn cancel" data-dismiss="modal">
                Cancel
              </button>
              <button
                :disabled="convertingProcessing"
                type="button"
                class="btn continue"
                @click="convertPointsDirect()"
              >
              {{ convertingProcessing ? "Processing..." : "Submit" }}
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
      <!-- Points conversion confirmation modal ends -->
    </div>

  </div>
</template>

<script>
import points from "../services/points";
import formatDate from "../helpers/extractDate";
import CF from "../helpers/currencyFormatter";
import { requestForSmartItems } from "../services/fetchSmartItemsForConversion";
import { getNextSmartItemForConversion } from "../services/getNextSmartItemForConversion";
import PointsLogHistory from "./PointsLogsHistory.vue"
import SmartItemModal from "./SmartItemModal.vue"
import Loader from "./Loader";
import $ from "jquery";

export default {
  components: {
    PointsLogHistory,
    SmartItemModal,
    Loader
  },
  data() {
    return {
      loading: true,
      convertingProcessing: false,
      directPoints: 0,
      indirectPoints: 0,
      totalPoints: 0,
      totalPointsConverted: 0,
      readyPoints: 0,
      pointsLog: [],
      smartItems: [],
      selectedSmartItem: null,
      fetchData: "",
      showRewardModal: false,
      selectedRewardItem: null,
      showPointsLog: true,
      showClaimModal: false,
      nextRewardItem: {},
      selectedpoints: 0,
      ponintsAmount: 0,
      percentageAmount: 0,
      amountDeduction: 0,
      userIDD: null,
      canConvert: "",
    };
  },
  

  methods: {
    convertPointsDirect() {
      this.convertingProcessing = true;
      let body = {
        points: this.selectedpoints,
      };

      body = JSON.stringify(body);

      points.convertPointDirect(body).then(data => {
        this.convertingProcessing = false;
        this.selectedpoints = 0;
        if (data.success) {
          this.$toast.success("Points convertion done");
          $("#confirmPointConv")
          .removeClass("fade")
          .modal("hide");
          this.pointsDetails();
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
        $("#confirmPointConv")
          .removeClass("fade")
          .modal("hide");
      })
      .finally(() => {
        this.convertingProcessing = false;
      });
    },
    closeConfirmation() {
      // console.log("Close Confirmation");
    },
    activeButtons(value) {
      this.selectedpoints = value
    },
    async getNextItem() {
      try {
          const response = await getNextSmartItemForConversion();
          // console.log("response next:", response);
          if (response.success) {
              this.nextRewardItem = response.data;
              // console.log("getNextSmart", response);
          } else {
              this.$toast.error(response.error);
          }
      } catch (error) {
          // console.error("Error converting points:", error);
          this.$toast.error(error);
          // Handle error
      }
    },
    async fetchSmartItems() {
      try {
        const data = await requestForSmartItems.fetchSmartItems();
        // console.log("Data from fetchitems: ", data)
        if (Array.isArray(data)) {
          const extractedData = data.map((item) => {
            return {
              id: item.id,
              amount: item.amount,
              imageUrl: item.imageurl,
              name: item.name,
              points: item.points
            }
          })
          this.smartItems = extractedData
          this.loading = false;
          // console.log("Extracted data: ", this.smartItems[0].amount)
        }
      } catch (error) {
        // console.error("Error fetching smart items:", error);
        this.$toast.error(error);
        this.loading = false;
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    toTwoDecimal(value) {
      return CF.toTwoDecimal(value);
    },
    toFourDecimal(value) {
      return CF.toFourDecimal(value);
    },
    showModal(item) {
      this.selectedRewardItem = item;
      this.showClaimModal = true;
    },
    closeModal() {
      this.selectedRewardItem = null;
      this.showClaimModal = false;
      // this.fetchSmartItems();
      // this.getNextItem();
      // console.log("closeModal1");
    },
    togglePointsLogHistory() {
      this.showPointsLog = !this.showPointsLog;
    },
    showSmartItemModal(smartItem) {
      this.selectedSmartItem = smartItem;
      const modal = document.getElementById('smartItemModal');
      if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
      }
    },
    closeSmartItemModal() {
      this.selectedSmartItem = null;
      const modal = document.getElementById('smartItemModal');
      this.fetchSmartItems();
      this.getNextItem();
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
      }
    },
    getFivePercent() {
      this.percentageAmount = ((this.selectedpoints * 1000) * 0.05);
      return this.amountDeduction = ((this.selectedpoints * 1000) - this.percentageAmount);
    },
    pointsDetails() {
      let userID = this.$store.getters.getUser.id;
      this.fetchData = "loading";

      points
      .pointDetails(userID)
      .then(data => {
        // console.log("datatata", data);
        if (data.success) {
          this.canConvert = data.can_convert_point;
          this.directPoints = data.direct;
          this.indirectPoints = data.indirect;
          this.totalPoints = data.total;
          this.totalPointsConverted = data.converted_points;
          this.pointsLog = data.pointslog;
          this.readyPoints = data.readypoint;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData = "loaded";
      });
    },
    scrollToHistory(section) {
      const element = this.$refs[section];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  created() {
    this.userIDD = this.$store.getters.getUser.id;
    this.pointsDetails();
    this.fetchSmartItems();
    this.getNextItem();
  },
  mounted() {
    // this.pointsDetails();
    this.fetchSmartItems();
    this.getNextItem();
    this.userIDD = this.$store.getters.getUser.id;
  },
  watch: {
    selectedpoints(newValue) {
      if (newValue) {
        this.getFivePercent();
      }
    },
    deep: true,
  },
  computed: {
  },
};
</script>

<style scoped>

.no-items-available{
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: content;
  padding: 20px;
  color: #555C74;
}
.current-have {
  font-size: 14px;
}

.smart-list {
  display: flex;
  list-style-type: none;
  margin-top: 50px;
  padding-bottom: 30px;
}

.smart-item {
  flex-grow: 1;
}

.smart-item-container {
  width: 100px;
  height: 100px;
  gap: 0px;
  /* opacity: 0px; */
  background: #FFF7E6;
  border-radius: 60px;
  cursor: pointer;

}

.smart-item-image {
  width: 65.33px;
  height: 65.33px;
  top: 17px;
  left: 17px;
  gap: 0px;
  /* opacity: 0px; */
  align-items: center;
  justify-content: center;
  color: #FFF7E6;

}

.smart-item-image img {
  display: flex;
  padding: 0.75rem;
  border-radius: 50px;
  background-color: #FFF7E6;
  width: 100px;
  color: #FFF7E6;
}

.smart-item-name {
  font-size: 0.75rem;
  margin-top: 2.75rem !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center !important;
  color: #555C74;

}

/* .modal {
  overflow-y: scroll;
  height: 100%;
} */

.smart-points-text {
  font-style: italic;
  font-size: 0.75rem;
  color: #555C74;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-family: Lato;
}

.smart-points-text a {
  color: #1e40af;
  font-family: Lato;
}


.smart-rewards-container {
  margin-top: 1rem;
}

.smart-rewards {
  margin-top: 1.25rem;
  font-weight: bold;
  color: #555C74;
  font-family: Lato;
}


.direct-points {
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 1rem;
  color: #555C74;
  font-family: Lato;
}


.direct-commission {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  font-weight: 200;
  font-size: 0.75rem;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555C74;

}


.second-commission {
  background-color: #F5F7FE;
  padding: 0.75rem;
  border-radius: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .second-commission {
    margin-top: 1rem;
  }
}


.points-to-go {
  font-size: 0.75rem;
  color: #EAB308;
  margin-top: 0.25rem;
  padding-bottom: 0.5rem;
}

.reward-info-gas {
  font-size: 0.75rem;
  color: #555C74;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 10px;
}

.reward-info-text {
  font-size: 0.75rem;
  color: #555C74;
  padding-top: 20px;
}


.reward-info {
  margin-right: 2.5rem;
  margin-top: 0.75rem;
}

.first-point {
  color: #2BC300;
  font-weight: bold;
  font-size: 4rem;
}

.text-point {
  font-size: 1.25rem;
}



.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.left-content,
.right-content {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content {
    flex-direction: row;
  }

  .left-content,
  .right-content {
    width: 50%;
  }
}

.conversion-message {
  font-weight: lighter;
  font-size: 0.75rem;
}

.conversion-message span {
  font-weight: bold;
}

.conversion-message button {
  color: #1e40af;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  outline: none;
}

.convert-button {
  border: 1px solid #0033EA !important;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 1.5rem;
  margin-top: 0.5rem;
  color: #6b46c1;
  border-color: #0033EA !important;
  background: none;
}

.top-point-container {
  background-color: #FFF7E6;
  padding: 0.75rem;
  border-radius: 1rem;
  border: none;
  margin-top: 0.5rem;
  padding-bottom: 30px;

  width: Fill (330.5px)px;
  height: Fill (159px)px;
  /* padding: 16px 0px 0px 0px; */
  gap: 8px;
  /* border-radius: 10px 0px 0px 0px; */
  /* opacity: 0px; */

}

.team-commission {
  margin-top: 0.5rem;
  margin-bottom: 0.95rem;
  font-weight: 200;
  font-size: 0.75rem;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-align: left;
  color: #555C74;


}

.indirect-points {
  font-size: 3rem;
  font-weight: bold;
  color: #555C74;
}




@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form>* {
  align-self: center;
}

label {
  display: block;
}

p {
  margin: 0;
}

.second-container {
  font-family: "Lato";
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 1px;
  max-width: 322px;
  /* border-radius: 10px; */
}

.second-container h4 {
  margin-bottom: .2rem;
}

.steps>*+* {
  margin-top: 8px;
}

.step {
  display: flex;
  gap: 1rem;
  position: relative;
}

.step-number-container {
  position: relative;
}

.step:not(:last-child) .step-number-container::after {
  content: "";
  position: absolute;
  height: calc(100% - 28px);
  width: 2px;
  border-radius: 5px;
  background-color: #b1b2b2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
  transition: background-color 500ms ease-in-out;
}

.step .step-count {
  color: #888fa7;

  font-size: 10px;
  line-height: 1.8;
}

.step .current-step-name {
  font-size: 12px;
  line-height: 1.8;
  color: #b1b2b2;
}

.step .status-pill {
  display: flex;
  font-size: 8px;
  letter-spacing: 0.16px;
  width: 62px;
  padding: 2px 8px;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0;
  border: 1px solid #b1b2b2;
}

.progress .status-pill {
  background: #d8effd;
  color: #5aa8d7;
  border-color: transparent;
}

.completed .status-pill {
  background: #e7faf1;
  color: #48b881;
  border-color: transparent;
}

.step .step-number {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: grid;
  place-items: center;
  font-weight: 500;
  border: 2px solid #b1b2b2;
  color: #b1b2b2;

  font-size: 12px;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.step-number {
  position: relative;
  overflow: hidden;
  transition: background-color 500ms ease-in-out;
}

.step-number .done-icon {
  position: absolute;
  background-color: #0033ea;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 350ms ease-in;
  opacity: 0;
}

.pending.step .step-name {
  color: #b1b2b2;
}

.progress.step {
  color: #b1b2b2;
}

.progress.step .current-step-name {
  color: #262626;
}

.progress.step:not(:last-child) .step-number-container::after,
.completed.step:not(:last-child) .step-number-container::after {
  background-color: #0033ea;
}

.progress.step .step-number,
.completed.step .step-number {
  border-color: #0033ea;
  background-color: #0033ea;

  color: #fff;
}

.completed.step .done-icon {
  opacity: 1;
}

.completed.step .current-step-name {
  color: #0033ea;
}

.major-layout {
  /* display: flex; */
  /* justify-content: space-between;
  gap: 1rem; */
  margin-top: 1rem;
}

.second-container {
  align-self: flex-start;
  flex-basis: 300px;
  border-radius: 10px;
  /* display: none; */
}

@media screen and (min-width: 1024px) {
  .second-container {
    display: block;
  }
}

.first-container {
  flex: 1;
}

.horizontal-line{
  width: 100px;
  height: 2px;
  top: 48px;
  left: 100px;
  gap: 0px;
  opacity: 0px;
  color: #FFB201;
}

/* Points Modal begins */
.total-points-text {
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #555C74;
  margin-bottom: 10px !important;
}
.total-points-number {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #2B3352;
  text-align: center;
  margin-bottom: 30px;
}

.points-body {
  padding: 15px !important;
  h6 {
    /* font-family: Lato; */
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    color: #555C74; 
    margin-bottom: 15px;
  }
}
.points-btn1, .points-btn2 {
  display: flex;
  margin: auto;
  gap: 10px;
  margin-bottom: 20px;
  padding: 0 15px;
  button {
    /* width: 74px; */
    min-height: 37px;
    padding: 8px;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #E7E7E7;
    /* font-family: Lato; */
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #555C74;
    box-shadow: none;
    outline: 0;
  }
  .activePointBtn {
    background-color: #FFB100;
    color: #FFFFFF;
  }
}
.text-input {
  height: 50px;
  outline: 0;
  box-shadow: none;
  font-size: 20px;
  font-weight: 500;
  color: #555C74;
  margin: 5px 0px 20px;
  text-align: center;
}
.text-input:disabled {
  background: inherit;
  /* border: inherit; */
}

.amount-breakdown {
  h6 {
    font-size: 16px;
    font-weight: 400;
  }
}

.orange-btn {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 20px;
  .cancel {
    border: 1px solid #FFB100;
    color: #FFB100;
    height: 48px;
    width: 100%;
    font-size: 16px;
    outline: 0;
    box-shadow: none;
  }
  .continue {
    background-color: #FFB100;
    color: #FFFFFF;
    height: 48px;
    width: 100%;
    font-size: 16px;
    outline: 0;
    box-shadow: none;
  }
}

/* Points modal ends */

@media only screen and (max-width: 599px) {
  .top-point-container {
    margin-top: 2rem;
  }
  .major-layout {
    display: block
  }
  .second-container {
    margin-top: 40px;
    max-width: 100%;
  }
  .reward-info {
    margin-bottom: 20px;
  }
  .indirect-points, .direct-points, .first-point {
    font-size: 2.5rem;
  }
  .first-point {
    margin-bottom: 10px !important;
  }
}

/* Reward items begins */
.the-wrapper:not(:last-child) {
  margin-right: 50px;
}

/* .the-wrapper::after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;

} */
.reward-item-holder {
  margin-top: 30px;
  overflow-x: scroll;
}

.img-holder {
  background: #FFF7E6;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
}
.reward-item {
  cursor: pointer;
}
.reward-item > h6 {
  margin-top: 15px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #555C74;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 108px;

}
.img-holder > img {
  width: 100%;
  border-radius: 50px;
}
.the-wrapper{
  position: relative
}
.the-wrapper:last-child::after {
  display: none !important;
  content: none;
}

.the-wrapper::after {
  background: #FFF7E6;
  content: "";
  display: inline-block;
  height: 2px;
  position: absolute;
  /* vertical-align: middle; */
  width: calc(100% - 50px);
  z-index: 10;
  left: 100px;
  top: 50px;
  /* bottom: 108px; */
}
/* Reward items ends */
</style>
