import client from "./requestclient";

export const getNextSmartItemForConversion = async () => {
    try {
      const response = await client({
        URL_PATH: "get-next-smart-item-for-conversion",
        method: "GET",
      });
  
      if (response.success) {
        // console.log("Get next smart item", response.data)
        return response; // Assuming the data is returned in response.data
      } else {
        // console.error("Failed to fetch next smart item:", response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching next smart item:", error);
      throw error;
    }
  };