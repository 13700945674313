import client from "./requestclient";

export const convertSmartPointsService = {
    convertPoints: async ({ itemId, conversionType }) => {
      try {
        // Use the client function to make a POST request to the API
        const response = await client({
          URL_PATH: "convert-smart-points",
          method: "POST",
          body: JSON.stringify({
            item_id: itemId,
            conversion_type: conversionType,
          }),
        });
  
        // Check the API response and handle accordingly
        if (response.success) {
          // console.log("Points converted successfully:", response);
          return response; // Assuming the API returns some data upon success
        } else {
          console.error("Failed to convert points:", response.message);
          throw new Error(response.message);
        }
      } catch (error) {
        console.error("Error converting points:", error);
        throw error;
      }
    },
  };