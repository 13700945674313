import client from "./requestclient";

export const requestForSmartItems = {
  fetchSmartItems: async () => {
    try {
      const response = await client({ URL_PATH: "fetchsmartitemsforconversion", method: "GET" });
      return response.items;
    } catch (error) {
      console.error("Error fetching smart items:", error);
      throw error;
    }
  },
};
