<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center">
                <div class="header">
                    <h5>Claim Reward</h5>
                    <button type="button" class="close-btn" @click="closeModal">
                        X
                    </button>
                </div>
                <div class="divider"></div>
                <div class="reward-info">
                    <p class="light-text">Total points</p>
                    <p class="bold-text">{{ selectedSmartItem.points }} points</p>
                    <img class="w-100" :src="selectedSmartItem.imageUrl" alt="">
                </div>
                <table class="table-info">

                    <tr>
                        <td>Total cash value</td>
                        <td>₦{{ Number(selectedSmartItem.amount).toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <td>Conversion charge(Cash)</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>Total receivable(Cash)</td>
                        <td>₦{{ Number((selectedSmartItem.amount) - (selectedSmartItem.amount * 0.05)).toLocaleString() }}</td>
                    </tr>
                </table>
                <hr>
                <div>
                    <p class="point-deducted">{{ selectedSmartItem.points }} points will be deducted from your points. Do you want to proceed?</p>
                </div>
                <div class="buttons">
                    <!-- <p>{{ processing }}</p> -->
                    <button :disabled="processing" class="convert-btn" @click="convertIncentive" :class="[processing ? 'processingBlink' : '']">
                        {{ processing ? "Converting..." : "Convert to Cash" }}
                    </button>
                    <button :disabled="processing2" class="receive-btn" @click="recieveItem" :class="[processing2 ? 'processingBlink' : '']">
                        {{ processing2 ? "Receiving..." : "Receive Item" }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { convertSmartPointsService } from "../services/convertSmartPoints";
// import { getNextSmartItemForConversion } from "../services/getNextSmartItemForConversion";

export default {
    name: "SmartItemModal",
    props: {
        selectedSmartItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
          processing: false,
          processing2: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        async recieveItem() {
            this.processing2 = true;
            try {
                await convertSmartPointsService.convertPoints({
                    itemId: this.selectedSmartItem.id,
                    conversionType: "item", 
                }).then(response => {
                    this.processing2 = false;
                    this.closeModal();
                    // console.log("Conversion response:", response);
                    if (response.success) {
                        this.$toast.success(response.success);
                    }
                    if (response.error) {
                        this.$toast.error(response.error);
                    }
                    
                })
                // Handle successful conversion
            } catch (error) {
                // console.error("Error converting points:", error);
                this.$toast.error(error);
                this.processing2 = false;
                // Handle error
            }
        },
        async convertIncentive() {
            this.processing = true;
            try {
                await convertSmartPointsService.convertPoints({
                    itemId: this.selectedSmartItem.id,
                    conversionType: "cash", 
                }).then(response => {
                    this.processing = false;
                    this.closeModal();
                    // console.log("Conversion response:", response);
                    if (response.success) {
                        this.$toast.success(response.success);
                    }
                    if (response.error) {
                        this.$toast.error(response.error);
                    }
                    
                })
                // Handle successful conversion
            } catch (error) {
                // console.error("Error converting points:", error);
                this.$toast.error(error);
                this.processing = false;
                // Handle error
            }
        },
    },
};
</script>

<style>
.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    max-width: 30rem;
    border-radius: 12px;
}

.modal-body {
    padding: 0rem !important;
}

.header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    align-items: center;
    padding: 1rem 1rem 0rem;
}

.header h5 {
    font-size: 14px;
    font-weight: 600;
}

.close-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.divider {
    border-bottom: 1px solid #E2E8F0;
    font-size: 20px;
    width: 100% !important;
}

.reward-info {
    margin-top: 10px;
    margin-bottom: 10px;
    image {
        width: 100%;
    }
}

.light-text {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #555C74;

}

.bold-text {
    font-family: Lato;
    font-size: 17px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #2B3352;
    /* margin-bottom: 1rem !important; */


}

.table-info {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #555C74;


    width: Fill (432px)px;
    height: Hug (56px)px;
    padding: 16px 0px 16px 0px;
    gap: 0px;
    opacity: 0px;


}

.processingBlink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.table-info td:first-child {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #fff;
    text-align: left;

    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

}

.table-info td:last-child {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #fff;
    text-align: right;
    /* font-family: Lato; */
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 15px 15px 50px 15px;
    gap: 10px;
}

.convert-btn {
    color: #FFB100;
    background-color: #fff;
    height: 48px;
    padding: 5px 27px 5px 27px;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid #FFB100;
    width: 100%;
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

}

.receive-btn {
    border: 1px solid #FFB100;
    background-color: #FFB100;
    color: #fff;
    border-radius: 6px;
    padding: 5px 27px 5px 27px;
    width: 100%;
    height: 48px;
    font-size: 0.875rem;
    cursor: pointer;
    text-align: center;
}

.convert-btn:hover {
    background-color: #FFB100;
    color: #fff
}

.receive-btn:hover {
    background-color: #fff;
    color: #FFB100;
}

.point-deducted {
    font-family: Lato;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #555C74;
    text-align: left;
    padding: 0 15px;
}
</style>